$('.send-security-package-btn').click(function (event) {
    event.preventDefault();

    let form = $(this).closest('form');
    form.find('.alert-message').hide().removeClass('in');

    $.post(form.attr('action'), JSON.stringify({
        project: form.find('input[name=project]').val(),
        name: form.find('input[name=name]').val(),
        email: form.find('input[name=email]').val(),
        companyUrl: form.find('input[name=companyUrl]').val(),
        companyRole: form.find('input[name=companyRole]').val(),
        gRecaptchaResponse: form.find('textarea[name=gRecaptchaResponse]').val(),
    }), 'json')
        .done(result => {
            form[0].reset();
            $('.modal').click();
            form.find('.alert-message').removeClass('in');

            setTimeout(() => {
                $('#form-modal-success').modal('show');
            }, 500);

            setTimeout(function () {
                $('#form-modal-success').modal('hide');
            }, 5000);
        })
        .fail((xhr, status, error) => {
            if (xhr.responseJSON && xhr.responseJSON.errors && xhr.responseJSON.errors.length) {
                xhr.responseJSON.errors.map((parseError) => {
                    if (parseError) {
                        form.find('.error-send-security-package-' + parseError.path).html(parseError.error).show().addClass('in');
                    }
                })
            }
        });
});